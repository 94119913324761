<template>
  <div ref="mapElement" class="z-0 h-full w-full"></div>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import bookingRepository from '@/repositories/bookings/bookingRepository'
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine';
import { useStore } from 'vuex'
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

const FromIcon = L.icon({
  iconUrl: 'icons/leaf-icon-from.png',
  iconSize: [20, 29],
  iconAnchor: [10, 29]
});

const ToIcon = L.icon({
  iconUrl: 'icons/leaf-icon-to.png',
  iconSize: [20, 29],
  iconAnchor: [10, 29]
});

const DeviationIcon = L.icon({
  iconUrl: 'icons/leaf-icon-deviation.png',
  iconSize: [20, 29],
  iconAnchor: [10, 29]
})

const SecondaryDestinationIcon = L.icon({
  iconUrl: 'icons/leaf-icon-secondary.png',
  iconSize: [20, 29],
  iconAnchor: [10, 29]
})

// No icon, used to influence route matching
const InvisibleIcon = L.icon({
  iconUrl: '',
  iconSize: [0, 0]
});

const store = useStore()
const stopRoute = store.getters['stopStore/stopRoute']
const booking = store.getters['bookingStore/booking']
const activeDeviation = store.getters['bookingStore/activeDeviation']

const mapElement = ref(null);
let map: L.Map;
let routingControl: L.Routing.Control;
let secondaryRoutingControl: L.Routing.Control;
const allPoints = [];
const markers: L.Marker[] = [];

onMounted(async () => {
  // ICONS
  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
  });

  // MAP
  if (stopRoute.latitude && stopRoute.longitude) {
    map = L.map(mapElement.value).setView([stopRoute.latitude, stopRoute.longitude], 16);
  } else {
    map = L.map(mapElement.value).setView([56.26392, 9.501785], 16);
  }

  L.tileLayer('https://{s}.basemaps.cartocdn.com/light_nolabels/{z}/{x}/{y}.png', {
    attribution: ''
  }).addTo(map);
  map.removeControl(map.zoomControl);

  // SAFE ROUTE
  let wayPoints = await fetchSafeRoute(booking.currentStop.fromAddress, booking.currentStop.toAddress)
  let secondaryWaypoints: L.LatLng[] = [];

  const color = activeDeviation === null ? '#DC2626' : '#10B981'

  const primaryStyles = [
    { color: 'gray', opacity: 0.8, weight: 6 },
    { color: 'white', opacity: 0.8, weight: 5 },
    { color: activeDeviation === null ? '#DC2626' : '#10B981', opacity: 1, weight: 2 }
  ];

  const secondaryStyles = [
    { color: 'gray', opacity: 0.8, weight: 6 },
    { color: 'white', opacity: 0.8, weight: 5 },
    { color: '#1E90FF', opacity: 1, weight: 2 } // Blue path for secondary address
  ];

  // FROM-TO
  if (booking.currentStop.fromAddress > 0) {
    addMarker(booking.currentStop.fromLat, booking.currentStop.fromLong, {icon: FromIcon})
    // if (wayPoints.length > 0) {
    //   if (color !== 'red') {
    //     const newWaypoint = L.latLng(booking.currentStop.fromLat, booking.currentStop.fromLong);
    //     if (wayPoints.length > 0) {
    //       wayPoints.splice(0, 0, newWaypoint);
    //     } else {
    //       wayPoints.push(newWaypoint);
    //     }
    //   }
    // }
  }

  if (booking.currentStop.toAddress > 0) {
    addMarker(booking.currentStop.toLat, booking.currentStop.toLong, {icon: ToIcon})
    // if (wayPoints.length > 0) {
    //     if(color !== 'red') {
    //     const newWaypoint = L.latLng(booking.currentStop.toLat, booking.currentStop.toLong);
    //     wayPoints.push(newWaypoint);
    //   }
    // }
  }

  // CURRENT POSITION
  if (stopRoute.latitude && stopRoute.longitude) {
    addCircleMarker(stopRoute.latitude, stopRoute.longitude, '#60A5FA', {})
  }

  // ACTIVE DEVIATION
  if (activeDeviation && activeDeviation.latitude && activeDeviation.longitude) {
    
    console.log('activeDeviation', activeDeviation)
    // Clear existing waypoints
    wayPoints = [];

    // map.eachLayer(function (layer) {
    //     if (layer instanceof L.Marker) {
    //         map.removeLayer(layer);
    //     }
    // });

    // Add deviation marker to destination
    addMarker(activeDeviation.latitude, activeDeviation.longitude, {icon: DeviationIcon})

    // Current position
    const currentLatLng = new L.LatLng(stopRoute.latitude, stopRoute.longitude);

    // Deviation POI
    const deviationLatLng = new L.LatLng(activeDeviation.latitude, activeDeviation.longitude);
    
    wayPoints.push(currentLatLng, deviationLatLng);
  }

  if (booking.currentStop.secondaryAddressId) {
    const waypoints = await fetchSafeRoute(booking.currentStop.fromAddress, booking.currentStop.secondaryAddressId)
    addMarker(booking.currentStop.secondaryAddressLatitude, booking.currentStop.secondaryAddressLongitude, {icon: SecondaryDestinationIcon})
    secondaryWaypoints = waypoints;
  }

  // ROUTING
  routingControl = L.Routing.control({
    waypoints: wayPoints,
    routeWhileDragging: false,
    show: false,
    itinerary: false,
    zoomControl: false,
    attributionControl: false,
    fitSelectedRoutes: false, // zoom effect, if true it will only consider waypoints
    showAlternatives: false,
    lineOptions: {
        styles: primaryStyles
    },
    router: L.Routing.osrmv1({
      serviceUrl: 'https://tdkosrmmapsservice.azurewebsites.net/api/TdkOsrmMapsService',
      profile: 'driving',
      useHints: false,
      routingOptions: {
        alternatives: false,
      }
    }),
    createMarker: function(i: any, waypoint: any, n: any) {
      return undefined;
    }
  }).addTo(map);

  if (booking.currentStop.secondaryAddressId) {
    // ROUTING
    secondaryRoutingControl = L.Routing.control({
      waypoints: secondaryWaypoints,
      routeWhileDragging: false,
      show: false,
      itinerary: false,
      zoomControl: false,
      attributionControl: false,
      fitSelectedRoutes: false, // zoom effect, if true it will only consider waypoints
      showAlternatives: false,
      lineOptions: {
          styles: secondaryStyles
      },
      router: L.Routing.osrmv1({
        serviceUrl: 'https://tdkosrmmapsservice.azurewebsites.net/api/TdkOsrmMapsService',
        profile: 'driving',
        useHints: false,
        routingOptions: {
          alternatives: false,
        }
      }),
      createMarker: function(i: any, waypoint: any, n: any) {
        return undefined;
      }
    }).addTo(map);
  }

  await routingControl.route()
});

function addMarker(lat: number, lng: number, options?: L.MarkerOptions) {
  if (lat && lng) {
    const marker = L.marker([lat, lng], options);
    marker.addTo(map);
  }
}

function addCircleMarker(lat: number, lng: number, color: string, options = {}) {
  if (lat && lng) {
    const circleOptions = {
      color: color,
      fillColor: color,
      fillOpacity: 1,
      radius: 5,
      ...options
    };

    const circleMarker = L.circleMarker([lat, lng], circleOptions);
    circleMarker.addTo(map);

    if (options && options.popup) {
      circleMarker.bindPopup(options.popup);
    }

    return circleMarker;
  } else {
    console.log(`Invalid lat:${lat} and/or ${lng}`)
  }

}

async function fetchSafeRoute (from: number, to: number) {
  const wayPoints: L.LatLng[] = [];
  try {
    const response = await bookingRepository.getSafeRoute(from, to)
    if (response && response.data) {
      response.data.forEach((c: { latitude: number; longitude: number; }, index: any) => {
        const newWaypoint = L.latLng(c.latitude, c.longitude);
        wayPoints.push(newWaypoint);
      })
    }
  } catch (error) {
    console.error('Failed to fetch safe route:', error)
  }
  return wayPoints;
}

watch(() => store.state.stopStore.stopRoute, (stopRoute) => {
  // MAP
  if (map && stopRoute.latitude && stopRoute.longitude) {
    const currentZoom = map.getZoom();
    //map.setView([stopRoute.latitude, stopRoute.longitude], currentZoom); // 16
    map.eachLayer(function(layer) {
      if (layer instanceof L.CircleMarker) {
        map.removeLayer(layer);
      }
    });

    addCircleMarker(stopRoute.latitude, stopRoute.longitude, '#60A5FA', {})

    if (activeDeviation) {
      const currentWaypoints = routingControl.getWaypoints();
      currentWaypoints.splice(0, 1, L.latLng(stopRoute.latitude, stopRoute.longitude));
      routingControl.setWaypoints(currentWaypoints);
    }
  }
})


</script>

<style>
.leaflet-routing-container {
  display: none;
}

.leaflet-bottom {
  display: none;
}
</style>
