import axiosClient from '@/core/axiosClient'
const resource = '/api/stop'

export default {
  async getStopRouteStatus (id: number, toAddress: number) {
    return await axiosClient.get(`${resource}/getStopRouteStatus/${id}?toAddress=${toAddress}`)
  },
  async updateStopAddress (stopId: number, time: Date, locationId: number) {
    return await axiosClient.put(`${resource}/updateStopAddress/${locationId}`, { time, locationId, stopId, user: '-' })
  },
  async startStop (stopId: number, time: Date, stopType: string) {
    return await axiosClient.post(`${resource}/startStop/${stopId}`, { time, stopType, stopId, user: '-' })
  },
  async endStop (stopId: number, time: Date, stopType: string, updatedQuantity: number, stopUnit: number) {
    return await axiosClient.post(`${resource}/endStop/${stopId}/${updatedQuantity}`, { time, updatedQuantity, stopType, stopId, user: '-', stopUnit })
  },
  async markAsNoCargo (stopId: number, time: Date) {
    return await axiosClient.post(`${resource}/noCargo/${stopId}`, { time, stopId, user: '-' })
  },
  async removeStop (stopId: number) {
    return await axiosClient.post(`${resource}/removeStop/${stopId}`)
  },
  async getStopUnits () {
    return await axiosClient.get(`${resource}/stop-units`)
  },
  async updateMemoDriver (stopId: number, memoDriver: string) {
    return await axiosClient.post(`${resource}/update-driver-memo/${stopId}`, { stopId, memoDriver })
  },
  async getPossibleSafeRouteDestinations(addressId: number) {
    return await axiosClient.get(`${resource}/safe-route-destinations/${addressId}`)
  },
  async toggleSecondaryRoute(stopId: number, addressId?: number) {
    return await axiosClient.post(`${resource}/toggle-secondary-route`, { stopId, addressId })
  }
}
