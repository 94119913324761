<template>
  <div>
    <section class="flex flex-col bg-gray-50 h-screen">
      <headerComponent></headerComponent>

      <section class="flex flex-col mt-16">

        <div class="">
          <a href="/" class="flex items-center text-sm text-gray-400">
              <div class="shrink-0 pl-6 pr-4">
                <svg class="fill-secondary rotate-180" width="9" height="40" viewBox="0 0 9 40" fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M8.02899 20.4773L2.56306 25.9432C2.29944 26.2068 1.87206 26.2068 1.60847 25.9432L0.97096 25.3057C0.707794 25.0425 0.707288 24.616 0.969835 24.3522L5.30168 20L0.969835 15.6478C0.707288 15.384 0.707794 14.9575 0.97096 14.6943L1.60847 14.0568C1.87208 13.7932 2.29947 13.7932 2.56306 14.0568L8.02896 19.5227C8.29257 19.7863 8.29257 20.2137 8.02899 20.4773Z" />
                </svg>
              </div>
              <div class="grow py-5">
                <div class="text-sm text-gray-400">Tur liste</div>
                <h1 class="text-xl font-medium text-primary">Dine allokerede ture</h1>
              </div>
            </a>
        </div>

        <ul class="space-y-4">
          <li class="bg-white px-6 py-5 text-sm text-gray-400" v-for="row in bookingList" :value="row.value" :key="row.value">
              <div class="mb-4">
                <div class="flex justify-between">
                  <h1 class="text-xl font-medium text-primary">{{ row.trailerName }}</h1>
                  <h1 class="text-xl font-medium text-primary">{{ row.temperature ? row.temperature.replace(',', '.') : '' }}</h1>
                </div>
                <div class="text-sm text-gray-400 flex justify-between">
                  {{ row.departureDate }}
                </div>
              </div>
              <ul role="list" class="divide-y divide-gray-200 border-y border-gray-200 p-0" v-for="subRow in row.bookings" :value="subRow.value" :key="subRow.value">
                <listItemBookingComponent :data="subRow"></listItemBookingComponent>
              </ul>
          </li>
        </ul>
      </section>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import headerComponent from '../../components/headerComponent.vue'
import listItemBookingComponent from '../../components/bookings/listItemBookingComponent.vue'
import { mapActions, mapGetters } from 'vuex'

export default defineComponent({
  name: 'bookingListView',
  data () {
    return {
      intervalId: null as number | null
    }
  },
  components: {
    headerComponent,
    listItemBookingComponent
  },
  beforeUnmount () {
    if (this.intervalId !== null) {
      clearInterval(this.intervalId)
    }
  },
  async mounted () {
    await this.getBookingList()
    // Set up interval to fetch booking list every 30 seconds
    this.intervalId = setInterval(async () => {
      console.log('running ', new Date())
      await this.getBookingList()
    }, 30000)
  },
  computed: {
    ...mapGetters('bookingStore', ['bookingList']),
    ...mapGetters('stopStore', ['stopRoute'])
  },
  methods: {
    ...mapActions('bookingStore', ['getBookingList']),
    ...mapActions('stopStore', ['getStopRouteStatus']),
  },
})
</script>
