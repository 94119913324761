<template>
  <li class="m-0 flex items-center p-0" v-if="data">
    <router-link :to="'/bookings/' + data.id" class="flex w-full gap-x-4 no-underline py-1.5">
      <div class="flex items-center shrink-0">
        <div class="w-4 h-4 bg-gray-100 rounded-full" v-if="data?.bookingStatus === 3"></div>
        <div class="w-4 h-4 bg-blue-400 rounded-full" v-if="data?.bookingStatus === 2"></div>
        <div class="w-4 h-4 border border-secondary rounded-full" v-if="data?.bookingStatus === 1 || !data?.bookingStatus"></div>
      </div>
      <div class="flex flex-col grow">
        <div class="text-base font-normal antialiased text-gray-400 flex mb-1 mt-1">
          <div class="flex-shrink-0 w-1 self-stretch mr-1" :class="getBookingHeaderColor(data?.bookingHeader)"></div>
          <div class="flex-grow leading-5 antialiased">
            <div>{{ data?.bookingHeader.toUpperCase() }} - {{ getRemark(data) }}</div>
          </div>
        </div>
        <div class="text-base font-medium antialiased leading-5 text-gray-800">{{ data?.bookingTitle }}</div>
      </div>
      <div class="flex items-center shrink-0">
        <svg class="w-8" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.029 20.4773L14.5631 25.9432C14.2995 26.2068 13.8721 26.2068 13.6085 25.9432L12.971 25.3057C12.7079 25.0425 12.7073 24.616 12.9699 24.3522L17.3017 20L12.9699 15.6478C12.7073 15.384 12.7079 14.9575 12.971 14.6943L13.6085 14.0568C13.8721 13.7932 14.2995 13.7932 14.5631 14.0568L20.029 19.5227C20.2926 19.7863 20.2926 20.2137 20.029 20.4773Z" fill="#E5E7EB"/>
        </svg>
      </div>
    </router-link>
  </li>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'listItemBookingComponent',
  components: { },
  props: {
    data: Object
  },
  methods: {
    getRemark(booking: any) : string | null {
      if (booking && booking.remarks) {
        const arr = booking.remarks as any[]
        return arr.find(f => f.reL_RemarkType === 1)?.remark
      }
      return null;
    },
    getBookingHeaderColor(bookingHeader: string) : string {
      switch (bookingHeader.toUpperCase().trim()) {
        case 'STUE':
          return 'bg-red-600'
        case 'KØL':
          return 'bg-green-600'
        case 'FROST':
          return 'bg-blue-600'
        case 'EJ LOG':
          return 'bg-yellow-600'
        default:
          return 'bg-gray-400'
      }
    }
  }
})
</script>
