<template>
  <div class="relative z-50 w-full h-full" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <transition name="fade" enter-active-class="ease-in-out duration-500" leave-active-class="ease-in-out duration-500"
      enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100"
      leave-to-class="opacity-0">
      <div v-if="isVisibleLog" class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity"></div>
    </transition>
    <transition name="slide" enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-from-class="translate-x-full" enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-from-class="translate-x-0" leave-to-class="translate-x-full">
      <div v-if="isVisibleLog" class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button @click="close()" type="button"
                  class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div class="flex h-full flex-col overflow-y-scroll bg-gray-100 py-6 shadow-xl">
                <div v-if="mode === 'read'" class="flex flex-col px-6 sm:px-6">
                  <span class="text-gray-400 text-sm">HANDLINGSLOG</span>
                  <span class="text-primary font-medium text-lg">Bemærkning</span>
                  <div class="py-6">
                    <span class="text-gray-600 text-sm">Bemærkning</span>
                    <textarea class="rounded-md w-full resize-none border text-primary border-gray-300 p-3 h-32"
                      placeholder="Skriv din bemærkning" disabled v-model="logDetails.details"></textarea>
                  </div>
                </div>
                <div v-if="mode === 'create'" class="flex flex-col px-6 sm:px-6">
                  <span class="text-gray-400 text-sm">HANDLINGSLOG</span>
                  <span class="text-primary font-medium text-lg">Bemærkning</span>
                  <div class="py-6">
                    <div
                        @click="triggerFileInput()"
                        class="p-4 border-dashed border-2 h-36 border-gray-300 rounded flex flex-col items-center justify-center relative overflow-visible">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                        <path stroke-linecap="round" stroke-linejoin="round" d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                      </svg>
                      <span v-if="!selectedFile" class="mt-2 text-gray-700 text-sm w-36 text-center">
                        Vælg eller tag evt. billede til dokumentation
                      </span>
                      <img v-if="imageUrl" :src="imageUrl" class="absolute top-0 left-0 w-full h-full object-cover rounded" />
                      <img v-if="imageUrl" @click.stop="removeImage" :src="imageDeleteIcon" class="absolute -top-3 -right-3 cursor-pointer" />
                      <input type="file" ref="fileInput" @change="handleFileChange" accept="image/*" class="hidden"/>
                    </div>
                  </div>
                  <div>
                    <span class="text-gray-600 text-sm">Bemærkning</span>
                    <textarea class="rounded-md w-full resize-none border text-primary border-gray-300 p-3 h-32"
                      v-model="newLog.details"></textarea>
                  </div>
                  <div class="py-3">
                    <button @click="saveLog()" :disabled="!newLog.details"
                      :class="{ 'opacity-20 pointer-events-none': !newLog.details }"
                      class="flex w-full justify-center rounded-md bg-primary px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                      Opret som bemærkning
                    </button>
                  </div>
                </div>
                <div v-if="mode === 'update'" class="flex flex-col px-6 sm:px-6">
                  <span class="text-gray-400 text-sm">HANDLINGSLOG</span>
                  <span class="text-primary font-medium text-lg">Bemærkning</span>
                  <div class="py-6">
                    <div
                      @click="triggerFileInput()"
                      class="p-4 border-dashed border-2 h-36 border-gray-300 rounded flex flex-col items-center justify-center relative overflow-visible">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                        stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M6.827 6.175A2.31 2.31 0 0 1 5.186 7.23c-.38.054-.757.112-1.134.175C2.999 7.58 2.25 8.507 2.25 9.574V18a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9.574c0-1.067-.75-1.994-1.802-2.169a47.865 47.865 0 0 0-1.134-.175 2.31 2.31 0 0 1-1.64-1.055l-.822-1.316a2.192 2.192 0 0 0-1.736-1.039 48.774 48.774 0 0 0-5.232 0 2.192 2.192 0 0 0-1.736 1.039l-.821 1.316Z" />
                        <path stroke-linecap="round" stroke-linejoin="round"
                          d="M16.5 12.75a4.5 4.5 0 1 1-9 0 4.5 4.5 0 0 1 9 0ZM18.75 10.5h.008v.008h-.008V10.5Z" />
                      </svg>
                      <span v-if="!updateLogDetails.imageReference" class="mt-2 text-gray-700 text-sm w-36 text-center">
                        Vælg eller tag evt. billede til dokumentation
                      </span>
                      <img v-if="updateLogDetails.imageReference" :src="updateLogDetails.imageReference" class="absolute top-0 left-0 w-full h-full object-cover rounded" />
                      <img v-if="updateLogDetails.imageReference" @click.stop="removeImage" :src="imageDeleteIcon" class="absolute -top-3 -right-3 cursor-pointer" />
                      <input type="file" ref="fileInput" @change="handleFileChange" accept="image/*" class="hidden" capture="environment" />
                    </div>
                  </div>
                  <div>
                    <span class="text-gray-600 text-sm">Bemærkning</span>
                    <textarea v-model="updateLogDetails.details"
                      class="rounded-md w-full resize-none border text-primary border-gray-300 p-3 h-32"></textarea>
                  </div>
                  <div class="py-3 space-y-4">
                    <button :disbled="!hasChanges" :class="{ 'opacity-20 pointer-events-none': !hasChanges }"
                      @click="saveLog()"
                      class="flex w-full justify-center rounded-md bg-primary px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                      Gem ændringer
                    </button>
                    <button @click="deleteLog()"
                      class="flex w-full justify-center font-medium text-red-500 underline underline-offset-2">
                      Slet denne bemærkning
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import bookingRepository from '@/repositories/bookings/bookingRepository'
import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'
import imageDeleteIcon from '@/assets/icons/image-delete-icon.svg'

const props = defineProps({
  isVisibleLog: Boolean
})

const store = useStore()
const route = useRoute()
const id = route.params.id
const close = () => {
  store.commit('logStore/toggleLogOpen')
}

const logDetails = computed(() => store.getters['logStore/logDetails'])
const mode = computed(() => store.getters['logStore/dataMode'])


const newLog = ref({
  details: '',
  imageReference: null,
  reL_Booking: id
})

const updateLogDetails = ref({ ...logDetails.value, reL_Booking: id })

const hasChanges = computed(() => {
  return JSON.stringify(updateLogDetails.value) !== JSON.stringify({ ...logDetails.value, reL_Booking: id })
})

watch(logDetails, (newVal) => {
  updateLogDetails.value = {
    ...newVal,
    reL_Booking: id
  }
  selectedFile.value = null
})

const saveLog = async () => {
  try {
    const saveValue = mode.value === 'update' ? updateLogDetails.value : newLog.value
    await bookingRepository.saveManualLog(saveValue)
    store.commit('logStore/toggleLogOpen')
    store.dispatch('logStore/getBookingDriverLog', id)
    newLog.value = {
      details: '',
      imageReference: null,
      reL_Booking: id
    }
  } catch (error) {
    console.log('error', error)
  }
}

const deleteLog = async () => {
  try {
    await bookingRepository.deleteManualLog(updateLogDetails.value.id, updateLogDetails.value.reL_Booking)
    store.commit('logStore/toggleLogOpen')
    store.dispatch('logStore/getBookingDriverLog', id)
  } catch (error) {
    console.log('error', error)
  }
}

const selectedFile = ref(null)
const fileInput = ref<File | null>(null)
const triggerFileInput = () => {
  if (fileInput.value !== null) {
    fileInput.value.click()
  }
}

const handleFileChange = (event: Event) => {
  const files = (event.target as HTMLInputElement).files
  if (files && files.length > 0) {
    const file = files[0]
    selectedFile.value = file
    const reader = new FileReader()
    reader.onload = (e) => {
      if (mode.value === 'update') {
        updateLogDetails.value.imageReference = e.target?.result
        imageMarkedForDeletion.value = false
      } else {
        newLog.value.imageReference = e.target?.result
      }
    }
    reader.readAsDataURL(file)
  }
}

const imageUrl = computed(() => {
  return selectedFile.value ? URL.createObjectURL(selectedFile.value) : null
})

const imageMarkedForDeletion = ref(false)
const removeImage = () => {
  selectedFile.value = null
  if (fileInput.value) {
    fileInput.value.value = ''
  }

  if (mode.value === 'update') {
    imageMarkedForDeletion.value = true
    updateLogDetails.value.imageReference = null
  } else {
    newLog.value.imageReference = null
  }
}

</script>
