<template>
  <div>
    <section class="flex flex-col bg-gray-50 h-screen" :class="{ 'overflow-hidden': isSubComponentOpen }">
      <headerComponent></headerComponent>

      <!-- Global notification live region, render this permanently at the end of the document -->
      <div aria-live="assertive" class="pointer-events-none fixed top-32 left-0 inset-0 flex px-4 py-6 items-start sm:p-6 z-50">
        <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
          <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
          <transition
            enter-active-class="transform ease-out duration-300 transition"
            enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
            leave-active-class="transition ease-in duration-100"
            leave-from-class="opacity-100"
            leave-to-class="opacity-0">
              <div v-if="!isActive" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5">
                <div class="p-4">
                  <div class="flex items-start">
                    <div class="flex-shrink-0">
                      <!-- <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" /> -->
                    </div>
                    <div class="ml-3 w-0 flex-1 pt-0.5" @click="isActive = true">
                      <p class="text-sm font-medium text-gray-900">Tur køres ikke i dag - {{ formatDate(booking.departureDate) }}</p>
                      <p class="mt-1 text-sm text-gray-500">De fleste funktioner er de-aktiveret.</p>
                    </div>
                  </div>
                </div>
              </div>
          </transition>
          <transition
          enter-active-class="transform ease-out duration-300 transition"
          enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
          enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
          leave-active-class="transition ease-in duration-100"
          leave-from-class="opacity-100"
          leave-to-class="opacity-0">
            <div v-if="showMessage" class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-gray-100 shadow-lg ring-1 ring-black ring-opacity-5">
              <div class="p-4">
                <div class="flex items-start">
                  <div class="flex-shrink-0">
                    <!-- <CheckCircleIcon class="h-6 w-6 text-green-400" aria-hidden="true" /> -->
                  </div>
                  <div class="ml-3 w-0 flex-1 pt-0.5" @click="showMessage = false">
                    <p class="text-sm font-medium text-gray-900">{{ mediumMessage }}</p>
                    <p class="mt-1 text-sm text-gray-500">{{ smallMessage }}</p>
                  </div>
                </div>
              </div>
            </div>
        </transition>
        </div>
      </div>

      <section class="flex flex-col mt-16">
        <div class="">
          <router-link :to="'/bookings'" class="flex items-center text-sm text-gray-400">
            <div class="shrink-0 pl-6 pr-4">
              <svg class="fill-secondary rotate-180" width="9" height="40" viewBox="0 0 9 40" fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M8.02899 20.4773L2.56306 25.9432C2.29944 26.2068 1.87206 26.2068 1.60847 25.9432L0.97096 25.3057C0.707794 25.0425 0.707288 24.616 0.969835 24.3522L5.30168 20L0.969835 15.6478C0.707288 15.384 0.707794 14.9575 0.97096 14.6943L1.60847 14.0568C1.87208 13.7932 2.29947 13.7932 2.56306 14.0568L8.02896 19.5227C8.29257 19.7863 8.29257 20.2137 8.02899 20.4773Z" />
              </svg>
            </div>
            <div class="grow py-5">
                <div class="text-sm text-gray-400">Tur</div>
              <h1 class="text-xl font-medium text-primary">{{ booking.bookingTitle }}</h1>
            </div>
            <div @click.stop.prevent="handleSelect()" class="z-40 py-5 pr-6 pl-4 h-full m-2" v-if="booking.isActive">
              <svg width="20" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10 8.5C10.8281 8.5 11.5 9.17188 11.5 10C11.5 10.8281 10.8281 11.5 10 11.5C9.17188 11.5 8.5 10.8281 8.5 10C8.5 9.17188 9.17188 8.5 10 8.5ZM8.5 5.25C8.5 6.07812 9.17188 6.75 10 6.75C10.8281 6.75 11.5 6.07812 11.5 5.25C11.5 4.42188 10.8281 3.75 10 3.75C9.17188 3.75 8.5 4.42188 8.5 5.25ZM8.5 14.75C8.5 15.5781 9.17188 16.25 10 16.25C10.8281 16.25 11.5 15.5781 11.5 14.75C11.5 13.9219 10.8281 13.25 10 13.25C9.17188 13.25 8.5 13.9219 8.5 14.75Z" fill="#858E97"/>
              </svg>
              <div ref="dropdownRef" @click.stop.prevent v-if="showSelect" class="absolute right-3 z-20 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabindex="-1">
                <div class="py-1" role="none">
                  <a
                  @click.stop.prevent="toggleSetTrailer()" class="text-gray-700 block px-4 py-2 text-sm font-medium" role="menuitem"
                  :disabled="!canChangeTrailer()"
                  :class="{'opacity-20 pointer-events-none': !canChangeTrailer()}">
                  Skift trailer
                </a>
                </div>
                <div class="py-1" role="none">
                  <a @click.stop.prevent="clickDefrost()"
                  :disabled="defrost !== null && defrost.timeEnded !== null"
                  :class="{'opacity-20' : defrost !== null && defrost.timeEnded !== null}"
                  class="text-gray-700 block px-4 py-2 text-sm font-medium" role="menuitem">{{ defrost === null ? 'Start defrost' : 'Afslut defrost' }}</a>
                </div>
                <div class="py-1" role="none" v-if="booking.isMonitoringPaused === false">
                  <a @click.stop.prevent="setBookingActionState('PAUSE')" class="text-gray-700 block px-4 py-2 text-sm font-medium" role="menuitem">Start pause</a>
                </div>
                <div class="py-1" role="none" v-if="booking.isMonitoringPaused">
                  <a @click.stop.prevent="setBookingActionState('RESUME')" class="text-gray-700 block px-4 py-2 text-sm font-medium" role="menuitem">Stop pause</a>
                </div>
                <div class="py-1" role="none">
                  <a @click.stop.prevent="setBookingActionState('STOP')" class="text-gray-700 block px-4 py-2 text-sm font-medium" role="menuitem">Afslut tur - intet gods</a>
                </div>
                <div class="py-1" role="none">
                  <a @click.stop.prevent="setBookingActionState('CANCEL')" class="text-gray-700 block px-4 py-2 text-sm font-medium" role="menuitem">Afslut tur</a>
                </div>
              </div>
            </div>
          </router-link>
        </div>

        <!-- CONTENTE ONCE ITS LOADED -->
        <ul class="space-y-4 overflow-hidden">

          <!-- INFO -->
          <li class="relative bg-white px-6 py-5 text-sm text-gray-400">

            <!-- TEMPERATURE -->
            <div class="flex mb-4">
              <div class="grow">
                <h1 class="text-xl font-medium text-primary">{{ stopRoute.currentTemperature }}°</h1>
                <div class="text-sm text-gray-400">{{ stopRoute.temperature }} sidste 10 min.</div>
                <div class="text-sm text-gray-400">Dør: {{ stopRoute.doorState === 'CLOSED' ? 'Lukket' :
                  stopRoute.doorState === 'OPEN' ? 'Åben' : stopRoute.doorState }}</div>
              </div>

              <!-- MAP -->
              <div class="grow-0">
                <button @click="onClickMap()"
                  class="flex w-10 h-10 rounded-full bg-secondary items-center justify-center hover:shadow-md">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M10 2.88889C8.06694 2.88889 6.5 4.45583 6.5 6.38889C6.5 7.95166 8.7875 10.8 9.66389 11.8339C9.84139 12.0433 10.1589 12.0433 10.3361 11.8339C11.2125 10.8 13.5 7.95166 13.5 6.38889C13.5 4.45583 11.9331 2.88889 10 2.88889ZM10 7.55555C9.35556 7.55555 8.83333 7.03333 8.83333 6.38889C8.83333 5.74444 9.35556 5.22222 10 5.22222C10.6444 5.22222 11.1667 5.74444 11.1667 6.38889C11.1667 7.03333 10.6444 7.55555 10 7.55555ZM2.55889 8.8875C2.39394 8.95347 2.25254 9.06735 2.15292 9.21445C2.0533 9.36155 2.00004 9.53512 2 9.71278V16.6661C2 16.9806 2.3175 17.1956 2.60944 17.0789L6.44444 15.3333V8.85889C6.19889 8.415 5.99806 7.98277 5.85417 7.56944L2.55889 8.8875ZM10 12.8797C9.60917 12.8797 9.23944 12.7081 8.98583 12.4086C8.43972 11.7642 7.85889 11.0303 7.33333 10.2775V15.3331L12.6667 17.1108V10.2778C12.1411 11.0303 11.5606 11.7644 11.0142 12.4089C10.7606 12.7081 10.3908 12.8797 10 12.8797ZM17.3906 7.36555L13.5556 9.11111V17.1111L17.4411 15.5569C17.6061 15.491 17.7475 15.3771 17.8471 15.23C17.9468 15.0829 18 14.9093 18 14.7317V7.77833C18 7.46389 17.6825 7.24889 17.3906 7.36555Z"
                      fill="white" />
                  </svg>
                </button>
              </div>
            </div>

            
            <!-- EXPANDER UP -->
            <div v-if="showStart > 0"
              class="w-full h-10 border-b-2 rounded-lg bg-gray-100 flex justify-center items-center cursor-pointer"
              @click="expand('up')">
              <svg width="37" height="40" viewBox="0 0 37 40" fill="#9cafa3" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_465_856)">
                <path d="M12.5418 24.5473L18.2676 18.8215C18.3961 18.693 18.6039 18.693 18.7324 18.8215L24.4582 24.5473C24.5867 24.6758 24.5867 24.8836 24.4582 25.0121L23.9168 25.5535C23.7883 25.682 23.5805 25.682 23.452 25.5535L18.5 20.6125L13.5481 25.5535C13.4196 25.682 13.2117 25.682 13.0832 25.5535L12.5418 25.0121C12.4133 24.8836 12.4133 24.6758 12.5418 24.5473ZM12.5418 20.6371L13.0832 21.1785C13.2117 21.307 13.4196 21.307 13.5481 21.1785L18.5 16.2375L23.452 21.1785C23.5805 21.307 23.7883 21.307 23.9168 21.1785L24.4582 20.6371C24.5867 20.5086 24.5867 20.3008 24.4582 20.1723L18.7324 14.4465C18.6039 14.318 18.3961 14.318 18.2676 14.4465L12.5418 20.1723C12.4133 20.3008 12.4133 20.5086 12.5418 20.6371Z"
                fill="#c8dbcf"/>
                </g>
              </svg>
              <div class="text-sm font-medium text-gray-500 flex items-center justify-center align-middle">
                Vis tidligere stop
              </div>
              <svg width="37" height="40" viewBox="0 0 37 40" fill="#9cafa3" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_465_856)">
                <path d="M12.5418 24.5473L18.2676 18.8215C18.3961 18.693 18.6039 18.693 18.7324 18.8215L24.4582 24.5473C24.5867 24.6758 24.5867 24.8836 24.4582 25.0121L23.9168 25.5535C23.7883 25.682 23.5805 25.682 23.452 25.5535L18.5 20.6125L13.5481 25.5535C13.4196 25.682 13.2117 25.682 13.0832 25.5535L12.5418 25.0121C12.4133 24.8836 12.4133 24.6758 12.5418 24.5473ZM12.5418 20.6371L13.0832 21.1785C13.2117 21.307 13.4196 21.307 13.5481 21.1785L18.5 16.2375L23.452 21.1785C23.5805 21.307 23.7883 21.307 23.9168 21.1785L24.4582 20.6371C24.5867 20.5086 24.5867 20.3008 24.4582 20.1723L18.7324 14.4465C18.6039 14.318 18.3961 14.318 18.2676 14.4465L12.5418 20.1723C12.4133 20.3008 12.4133 20.5086 12.5418 20.6371Z"
                fill="#c8dbcf"/>
                </g>
              </svg>
            </div>

            <ul role="list" class="p-0">
              <!-- DEFROST START -->
              <li class="m-0 flex items-center p-0 relative" v-if="booking.defrostState >= 0">
                <div class="absolute left-2 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-100" aria-hidden="true"></div>
                <a @click.stop.prevent="clickDefrost()" class="flex w-full z-10 gap-x-4 no-underline py-1.5">
                  <div class="flex items-center shrink-0">
                    <div class="w-4 h-4 rounded-full z-10" :class="{
                      'bg-gray-100 pointer-events-none': booking.defrostState === 3,
                      'bg-blue-400': booking.defrostState > 0 && booking.defrostState < 3,
                      'border border-secondary bg-white': booking.defrostState === 0
                    }"></div>
                  </div>
                  <div class="flex flex-col grow">
                    <div class="text-sm text-gray-400">-</div>
                    <div class="text-base font-medium leading-5 text-gray-800">Defrost</div>
                  </div>
                  <div class="flex items-center shrink-0">
                    <svg class="w-8" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.029 20.4773L14.5631 25.9432C14.2995 26.2068 13.8721 26.2068 13.6085 25.9432L12.971 25.3057C12.7079 25.0425 12.7073 24.616 12.9699 24.3522L17.3017 20L12.9699 15.6478C12.7073 15.384 12.7079 14.9575 12.971 14.6943L13.6085 14.0568C13.8721 13.7932 14.2995 13.7932 14.5631 14.0568L20.029 19.5227C20.2926 19.7863 20.2926 20.2137 20.029 20.4773Z"
                        fill="#E5E7EB" />
                    </svg>
                  </div>
                </a>
              </li>

              <!-- IMAGE START -->
              <li class="m-0 flex items-center p-0 relative" v-if="booking.imageStartState >= 0">
                <div class="absolute left-2 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-100" aria-hidden="true"></div>
                <a @click.stop.prevent="setBookingActionState('IMAGESTART')" class="flex w-full z-10 gap-x-4 no-underline py-1.5">
                  <div class="flex items-center shrink-0">
                    <div class="w-4 h-4 rounded-full z-10" :class="{
                      'bg-gray-100 pointer-events-none': booking.imageStartState === 1,
                      'border border-secondary bg-white': booking.imageStartState === 0
                    }"></div>
                  </div>
                  <div class="flex flex-col grow">
                    <div class="text-sm text-gray-400">Det er nødvendigt at tage et billede før man kan starte</div>
                    <div class="text-base font-medium leading-5 text-gray-800">Tag et billede</div>
                  </div>
                  <div class="flex items-center shrink-0">
                    <svg class="w-8" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.029 20.4773L14.5631 25.9432C14.2995 26.2068 13.8721 26.2068 13.6085 25.9432L12.971 25.3057C12.7079 25.0425 12.7073 24.616 12.9699 24.3522L17.3017 20L12.9699 15.6478C12.7073 15.384 12.7079 14.9575 12.971 14.6943L13.6085 14.0568C13.8721 13.7932 14.2995 13.7932 14.5631 14.0568L20.029 19.5227C20.2926 19.7863 20.2926 20.2137 20.029 20.4773Z"
                        fill="#E5E7EB" />
                    </svg>
                  </div>
                </a>
              </li>

              <!-- STOPS -->
              <ul>
                <!-- Using the template tag to allow multiple root nodes in the loop -->
                <template v-for="(stop, index) in visibleStops" :key="index">
                  <!-- Conditionally render the placeholder as a separate list item -->
                  <li v-if="shouldShowPlaceholder(index)" class="m-0 flex rounded-sm items-center p-0 relative bg-gray-100"
                    @click="toggleDeviation()"
                    :class="{'bg-red-400': activeDeviation || booking.currentStop.secondaryAddressId}">
                    <div
                    :class="{'bg-red-400': activeDeviation || booking.currentStop.secondaryAddressId}" class="absolute left-2 top-4 z-10 -ml-px mt-0.5 h-full w-0.5 bg-gray-100" aria-hidden="true"></div>
                    <div class="flex w-full z-10 gap-x-4 no-underline py-1.5">
                      <div class="flex items-center shrink-0">
                        <div class="w-4 h-4">&nbsp;</div>
                      </div>
                        <div class="flex flex-col grow items-start justify-center">
                          <div class="text-base text-gray-400" :class="{'text-white': activeDeviation || booking.currentStop.secondaryAddressId}" v-html="deviationText"></div>
                        </div>
                      <div class="flex items-center shrink-0">
                        <svg class="w-8" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.029 20.4773L14.5631 25.9432C14.2995 26.2068 13.8721 26.2068 13.6085 25.9432L12.971 25.3057C12.7079 25.0425 12.7073 24.616 12.9699 24.3522L17.3017 20L12.9699 15.6478C12.7073 15.384 12.7079 14.9575 12.971 14.6943L13.6085 14.0568C13.8721 13.7932 14.2995 13.7932 14.5631 14.0568L20.029 19.5227C20.2926 19.7863 20.2926 20.2137 20.029 20.4773Z"
                            fill="#E5E7EB" />
                        </svg>
                      </div>
                    </div>
                  </li>
                  <!-- Regular stop rendering -->
                  <li class="m-0 flex items-center p-0 relative">
                    <div class="absolute left-2 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-100" aria-hidden="true"></div>
                    <a @click="showStopDetails(stop)" class="flex w-full z-10 gap-x-4 no-underline py-1.5">
                      <div class="flex items-center shrink-0">
                        <div class="w-4 h-4 rounded-full z-10" :class="{
                          'bg-gray-100 pointer-events-none': stop.stopDone,
                          'bg-blue-400': stop.stopStarted && !stop.stopDone,
                          'border border-secondary bg-white': !stop.stopStarted
                        }"></div>
                      </div>
                      <div class="flex flex-col grow">
                        <div class="text-sm text-gray-400">{{ stop.memoInternal !== null && stop.memoInternal !== undefined && stop.memoInternal !== '' ? stop.memoInternal : '-' }}</div>
                        <div class="text-base font-medium leading-5 text-gray-800">{{ stop.fromAddressStop }}</div>
                      </div>
                      <div class="flex items-center shrink-0">
                        <svg class="w-8" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M20.029 20.4773L14.5631 25.9432C14.2995 26.2068 13.8721 26.2068 13.6085 25.9432L12.971 25.3057C12.7079 25.0425 12.7073 24.616 12.9699 24.3522L17.3017 20L12.9699 15.6478C12.7073 15.384 12.7079 14.9575 12.971 14.6943L13.6085 14.0568C13.8721 13.7932 14.2995 13.7932 14.5631 14.0568L20.029 19.5227C20.2926 19.7863 20.2926 20.2137 20.029 20.4773Z"
                            fill="#E5E7EB" />
                        </svg>
                      </div>
                    </a>
                  </li>
                </template>
              </ul>

              <!-- IMAGE END -->
              <li class="m-0 flex items-center p-0 relative" v-if="booking.imageEndState >= 0">
                <div class="absolute left-2 top-4 -ml-px mt-0.5 h-full w-0.5 bg-gray-100" aria-hidden="true"></div>
                <a @click.stop.prevent="setBookingActionState('IMAGEEND')" class="flex w-full z-10 gap-x-4 no-underline py-1.5">
                  <div class="flex items-center shrink-0">
                    <div class="w-4 h-4 rounded-full z-10" :class="{
                      'bg-gray-100 pointer-events-none': booking.imageEndState === 1,
                      'border border-secondary bg-white': booking.imageEndState === 0
                    }"></div>
                  </div>
                  <div class="flex flex-col grow">
                    <div class="text-sm text-gray-400">Det er nødvendigt at tage et billede før man kan afslutte</div>
                    <div class="text-base font-medium leading-5 text-gray-800">Tag et billede</div>
                  </div>
                  <div class="flex items-center shrink-0">
                    <svg class="w-8" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.029 20.4773L14.5631 25.9432C14.2995 26.2068 13.8721 26.2068 13.6085 25.9432L12.971 25.3057C12.7079 25.0425 12.7073 24.616 12.9699 24.3522L17.3017 20L12.9699 15.6478C12.7073 15.384 12.7079 14.9575 12.971 14.6943L13.6085 14.0568C13.8721 13.7932 14.2995 13.7932 14.5631 14.0568L20.029 19.5227C20.2926 19.7863 20.2926 20.2137 20.029 20.4773Z"
                        fill="#E5E7EB" />
                    </svg>
                  </div>
                </a>
              </li>

              <!-- WASH -->
              <li class="m-0 flex items-center p-0 relative">
                <a @click="showWash()" class="flex w-full z-10 gap-x-4 no-underline py-1.5">
                  <div class="flex items-center shrink-0">
                    <div class="w-4 h-4 rounded-full z-10" :class="{
                      'bg-gray-100 pointer-events-none': booking.washState == 1 || booking.status === 3,
                      'border border-secondary bg-white': booking.washState == 0 && booking.status !== 3
                    }"></div>
                  </div>
                  <div class="flex flex-col grow">
                    <div class="text-sm text-gray-400">-</div>
                    <div class="text-base font-medium leading-5 text-gray-800">{{ booking.washState == 0 && booking.status < 3 ? 'Registering af vask' : 'Registering af vask er udført'}}</div>
                  </div>
                  <div class="flex items-center shrink-0">
                    <svg class="w-8" viewBox="0 0 33 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M20.029 20.4773L14.5631 25.9432C14.2995 26.2068 13.8721 26.2068 13.6085 25.9432L12.971 25.3057C12.7079 25.0425 12.7073 24.616 12.9699 24.3522L17.3017 20L12.9699 15.6478C12.7073 15.384 12.7079 14.9575 12.971 14.6943L13.6085 14.0568C13.8721 13.7932 14.2995 13.7932 14.5631 14.0568L20.029 19.5227C20.2926 19.7863 20.2926 20.2137 20.029 20.4773Z"
                        fill="#E5E7EB" />
                    </svg>
                  </div>
                </a>
              </li>
            </ul>
            <!-- EXPANDER DOWN -->
            <div v-if="showEnd < booking?.stops?.length"
              class="w-full h-10 mt-2 border-b-2 rounded-lg bg-gray-100 flex justify-center items-center cursor-pointer"
              @click="expand('down')">
              <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g transform="rotate(180 18.5 20)">
                  <g clip-path="url(#clip0_465_856)">
                    <path d="M12.5418 24.5473L18.2676 18.8215C18.3961 18.693 18.6039 18.693 18.7324 18.8215L24.4582 24.5473C24.5867 24.6758 24.5867 24.8836 24.4582 25.0121L23.9168 25.5535C23.7883 25.682 23.5805 25.682 23.452 25.5535L18.5 20.6125L13.5481 25.5535C13.4196 25.682 13.2117 25.682 13.0832 25.5535L12.5418 25.0121C12.4133 24.8836 12.4133 24.6758 12.5418 24.5473ZM12.5418 20.6371L13.0832 21.1785C13.2117 21.307 13.4196 21.307 13.5481 21.1785L18.5 16.2375L23.452 21.1785C23.5805 21.307 23.7883 21.307 23.9168 21.1785L24.4582 20.6371C24.5867 20.5086 24.5867 20.3008 24.4582 20.1723L18.7324 14.4465C18.6039 14.318 18.3961 14.318 18.2676 14.4465L12.5418 20.1723C12.4133 20.3008 12.4133 20.5086 12.5418 20.6371Z"
                    fill="#c8dbcf"/>
                  </g>
                </g>
              </svg>
              <div class="text-sm font-medium text-gray-500 flex items-center justify-center align-middle">
                Vis senere stop
              </div>
              <svg width="37" height="40" viewBox="0 0 37 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g transform="rotate(180 18.5 20)">
                  <g clip-path="url(#clip0_465_856)">
                    <path d="M12.5418 24.5473L18.2676 18.8215C18.3961 18.693 18.6039 18.693 18.7324 18.8215L24.4582 24.5473C24.5867 24.6758 24.5867 24.8836 24.4582 25.0121L23.9168 25.5535C23.7883 25.682 23.5805 25.682 23.452 25.5535L18.5 20.6125L13.5481 25.5535C13.4196 25.682 13.2117 25.682 13.0832 25.5535L12.5418 25.0121C12.4133 24.8836 12.4133 24.6758 12.5418 24.5473ZM12.5418 20.6371L13.0832 21.1785C13.2117 21.307 13.4196 21.307 13.5481 21.1785L18.5 16.2375L23.452 21.1785C23.5805 21.307 23.7883 21.307 23.9168 21.1785L24.4582 20.6371C24.5867 20.5086 24.5867 20.3008 24.4582 20.1723L18.7324 14.4465C18.6039 14.318 18.3961 14.318 18.2676 14.4465L12.5418 20.1723C12.4133 20.3008 12.4133 20.5086 12.5418 20.6371Z"
                    fill="#c8dbcf"/>
                  </g>
                </g>
              </svg>
            </div>
          </li>

          <li class="bg-white px-6 py-5 text-sm text-gray-400">
            <div class="flex items-center mb-4">
              <div class="grow">
                <h1 class="text-xl font-medium text-primary">Bemærkninger til tur</h1>
              </div>
            </div>
            <div v-if="booking.remarks && booking.remarks.length">
              <ul v-for="remark in booking.remarks" :key="remark.reL_RemarkType" role="list" class="divide-y divide-gray-200 border-y border-gray-200 p-0">
                <li class="m-0 flex items-center p-0">
                  <div class="flex w-full gap-x-4 no-underline py-1.5">
                    <div class="grid grow">
                      <div class="text-base font-medium leading-5 text-gray-800"> </div>
                      <div class="text-base font-medium leading-5 text-gray-800">
                        {{ remark.remark }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <span v-else class="text-primary text-sm">
              Der er ingen bemærkninger
            </span>
          </li>

          <!-- HANDLINGSLOG PÅ TUR -->
          <li class="bg-white px-6 py-5 text-sm text-gray-400">
            <div class="flex items-center mb-4">
              <div class="grow">
                <h1 class="text-xl font-medium text-primary">Handlingslog på tur</h1>
              </div>
              <div class="grow-0">
                <button
                  @click="addRemark()"
                  class="flex w-10 h-10 rounded-full border border-secondary items-center justify-center hover:shadow-md">
                  <svg class="fill-secondary" width="20" height="20" viewBox="0 0 20 20" fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M10 5C10.5523 5 11 5.44772 11 6V9L14 9C14.5523 9 15 9.44772 15 10C15 10.5523 14.5523 11 14 11H11V14C11 14.5523 10.5523 15 10 15C9.44771 15 9 14.5523 9 14V11H6C5.44772 11 5 10.5523 5 10C5 9.44771 5.44772 9 6 9L9 9V6C9 5.44772 9.44771 5 10 5Z" />
                  </svg>
                </button>
              </div>
            </div>
            <div v-if="logList.length">
              <ul v-for="log in logList" :key="log.id" role="list" class="divide-y divide-gray-200 border-y border-gray-200 p-0">
                <li class="m-0 flex items-center p-0">
                  <a @click="openBookingLogDetails(log)" class="flex w-full gap-x-4 no-underline py-1.5">
                    <div class="grid grow">
                      <div class="text-base font-medium leading-5 text-gray-800">{{ formattedTime(log.timestamp) }}</div>
                      <div class="text-sm truncate text-gray-400">
                        {{ log.details }}
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
            <span v-else class="text-primary text-sm">
              Der er endnu ingen handlinger
            </span>
          </li>

          <!-- RELATEREDE DOKUMENTER -->
          <li class="bg-white px-6 py-5 text-sm text-gray-400 hidden">
            <div class="flex items-center mb-4">
              <div class="grow">
                <h1 class="text-xl font-medium text-primary">Relaterede dokumenter</h1>
              </div>
              <div class="grow-0">
              </div>
            </div>
            <ul role="list" class="divide-y divide-gray-200 border-y border-gray-200 p-0">
              <li class="m-0 flex items-center p-0">
                <a href="#" class="flex w-full gap-x-4 no-underline py-1.5">
                  <div class="grid grow">
                    <div class="text-sm truncate text-gray-400">Trailer certificering</div>
                    <div class="text-base font-medium leading-5 text-gray-800">DJ85517-TestRapport.pdf</div>
                  </div>
                  <div class="flex items-center shrink-0">
                    <button class="p-0 px-3">
                      <svg class="fill-secondary" width="18" height="16" viewBox="0 0 18 16" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M15.2916 6.42782C15.1791 4.79657 13.8206 3.50001 12.15 3.50001C11.9362 3.50001 11.7253 3.52251 11.52 3.5647C10.6228 2.4397 9.22781 1.70001 7.65 1.70001C5.27063 1.70001 3.27656 3.37907 2.80406 5.63189C1.12219 6.31532 0 7.95501 0 9.80001C0 12.2863 2.01375 14.3 4.5 14.3H13.95C16.1859 14.3 18 12.4859 18 10.25C18 8.51189 16.8975 6.99314 15.2916 6.42782ZM13.95 12.95H4.5C2.75906 12.95 1.35 11.5409 1.35 9.80001C1.35 8.21376 2.52281 6.90032 4.05 6.68095V6.65001C4.05 4.66157 5.66156 3.05001 7.65 3.05001C9.15469 3.05001 10.4428 3.97251 10.98 5.28314C11.295 5.01314 11.7028 4.85001 12.15 4.85001C13.1428 4.85001 13.95 5.6572 13.95 6.65001C13.95 6.98189 13.86 7.29407 13.7025 7.56126C13.7841 7.55282 13.8684 7.55001 13.95 7.55001C15.4406 7.55001 16.65 8.75939 16.65 10.25C16.65 11.7406 15.4406 12.95 13.95 12.95ZM10.7887 7.98876C10.6566 7.85657 10.44 7.85657 10.3078 7.99157L8.775 9.56095V5.63751C8.775 5.45189 8.62313 5.30001 8.4375 5.30001H7.7625C7.57687 5.30001 7.425 5.45189 7.425 5.63751V9.56095L5.89219 7.99157C5.76 7.85657 5.54625 7.85657 5.41125 7.98876L4.93594 8.46407C4.80375 8.59626 4.80375 8.81001 4.93594 8.9422L7.86094 11.8672C7.99312 11.9994 8.20687 11.9994 8.33906 11.8672L11.2641 8.9422C11.3962 8.81001 11.3962 8.59626 11.2641 8.46407L10.7887 7.98876Z" />
                      </svg>
                    </button>
                  </div>
                </a>
              </li>
            </ul>
          </li>
        </ul>

        <section class="flex flex-col bg-gray-50 h-5"></section>
      </section>

      <stopDetailsComponent :isVisible="isSubComponentOpen"></stopDetailsComponent>
      <logDetailsComponent :isVisibleLog="isLogComponentOpen"></logDetailsComponent>
      <washDetailsComponent :isVisible="isWashOpen"></washDetailsComponent>
      <defrostComponent :isVisibleDefrost="isDefrostComponentOpen"></defrostComponent>
      <trailerDetailsComponent :isVisible="isTrailerComponentOpen"></trailerDetailsComponent>
      <bookingActionComponent :isVisible="hasBookingActionState"></bookingActionComponent>
      <deviationComponent :isVisibleDeviation="isDeviationOpen" :bookingId="id"></deviationComponent>
      <div v-if="isMapOverlayVisible" class="h-full w-full top-0 fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-80">
        <mapComponent></mapComponent>
        <button @click="closeMap"
          class="absolute top-5 left-5 bg-primary text-white p-2 rounded-full hover:bg-red-600 focus:outline-none focus:ring-4 focus:ring-red-300">
          <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
    </section>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { mapActions, mapGetters, mapState, useStore } from 'vuex'
import headerComponent from '../../components/headerComponent.vue'
import stopDetailsComponent from '@/components/bookings/stopDetailsComponent.vue'
import logDetailsComponent from '@/components/bookings/logDetailsComponent.vue'
import washDetailsComponent from '@/components/bookings/washDetailsComponent.vue'
import mapComponent from '@/components/map/mapComponent.vue'
import defrostComponent from '@/components/bookings/defrostComponent.vue'
import bookingActionComponent from '@/components/bookings/bookingActionComponent.vue'
import trailerDetailsComponent from '@/components/bookings/trailerDetailsComponent.vue'
import deviationComponent from '@/components/bookings/deviationComponent.vue'
import { useRoute } from 'vue-router'
import { useGeolocation } from '@/core/tools/useGeolocation'
import { formatToTime } from '@/core/utils/dateFormatter'
import { filter } from 'lodash'

const { coords } = useGeolocation()

export default defineComponent({
  name: 'bookingListView',
  data () {
    return {
      center: { lat: 0, lng: 0 },
      markers: [{
        position: {
          lat: 0, lng: 0
        }
      }],
      circles: [{
        position: {
          lat: 0, lng: 0
        }
      }],
      circleOptions: {
        strokeColor: '#FF0000',
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillColor: '#FF0000',
        fillOpacity: 0.35
      },
      map: ref(null),
      mapDiv: ref(null),
      counters: 0,
      isMapOverlayVisible: false,
      id: null as string | string[] | number | null,
      showSelect: false,
      showDefrost: false,
      initialStops: 5,
      showStart: 0,
      showEnd: 5,
      isLoading: true,
      isActive: true,
      showMessage: false,
      mediumMessage: '',
      smallMessage: ''
    }
  },
  components: {
    headerComponent,
    stopDetailsComponent,
    mapComponent,
    logDetailsComponent,
    washDetailsComponent,
    defrostComponent,
    bookingActionComponent,
    trailerDetailsComponent,
    deviationComponent,
  },
  props: {
  },
  beforeUnmount () {
    clearInterval(this.counters)
    document.removeEventListener('click', this.handleClickOutside)
  },
  async mounted () {
    this.isLoading = true
    const route = useRoute()
    document.addEventListener('click', this.handleClickOutside)
    this.id = route.params.id
    console.log('this.id', this.id)
    await this.getBooking(this.id)
    await this.getBookingDriverLog(this.id)
    await this.getDefrost(this.id)
    await this.getActiveDeviation(this.id)

    this.center.lat = this.currentPosition.lat
    this.center.lng = this.currentPosition.lng
    this.markers = [{
      position: {
        lat: this.center.lat, lng: this.center.lng
      }
    }]

    await this.initCircles()
    await this.timer()
    this.updateVisibleRange();
    this.isActive = this.booking.isActive
    this.isLoading = false
  },
  computed: {
    ...mapGetters('bookingStore', ['booking', 'bookingRoute', 'trailerOptions', 'activeDeviation']),
    ...mapGetters('stopStore', ['stopRoute']),
    ...mapGetters('logStore', ['logList']),
    ...mapGetters('defrostStore', ['defrost']),
    currentPosition () {
      return {
        lat: coords.value.latitude,
        lng: coords.value.longitude
      }
    },
    isSubComponentOpen () {
      console.log('isSubComponentOpen', this.$store.state.stopStore.detailsOpen)
      return this.$store.state.stopStore.detailsOpen
    },
    isDefrostComponentOpen () {
      return this.$store.state.defrostStore.toggleOpen
    },
    isTrailerComponentOpen () {
      return this.$store.state.bookingStore.toggleSetTrailer
    },
    isLogComponentOpen () {
      return this.$store.state.logStore.logOpen
    },
    isDeviationOpen () {
      return this.$store.state.bookingStore.deviationOpen
    },
    isWashOpen () {
      return this.$store.state.bookingStore.washOpen
    },
    hasBookingActionState () {
      return this.$store.state.bookingStore.actionState !== undefined
    },
    bookingActionState () {
      return this.$store.state.bookingStore.actionState
    },
    formattedTime (): (timestamp: string) => string {
      return (timestamp) => {
        if (!timestamp) return ''
        return formatToTime(timestamp)
      }
    },
    canExpandUp() {
      return this.showStart > 0;
    },
    canExpandDown() {
      return this.showEnd < (this.booking?.stops?.length || 0);
    },
    visibleStops() {
      const stops = this.booking && Array.isArray(this.booking.stops) ? this.booking.stops : [];
      return stops.slice(this.showStart, this.showEnd);
    },
    deviationText() {
      if (this.booking?.currentStop?.secondaryAddressId) {
        return `Afvigelse: Sekundær rute til <b>${this.booking.currentStop.secondaryAddressName}</b>`;
      }
      return this.activeDeviation && this.activeDeviation.name
        ? 'Afvigelse: ' + this.activeDeviation.name
        : 'Angiv afvigelse';
    }
  },
  methods: {
    ...mapActions('bookingStore', ['getBooking', 'getBookingRoute', 'getActiveDeviation']),
    ...mapActions('stopStore', ['getStopRouteStatus']),
    ...mapActions('logStore', ['getBookingDriverLog']),
    ...mapActions('defrostStore', ['getDefrost']),
    async initCircles () {
      this.circles = []

      if (this.booking.currentStop) {
        this.center.lat = this.booking.currentStop.fromLat
        this.center.lng = this.booking.currentStop.fromLong
        this.circles.push({
          position: {
            lat: this.booking.currentStop.fromLat, lng: this.booking.currentStop.fromLong
          }
        })
        this.circles.push({
          position: {
            lat: this.booking.currentStop.toLat, lng: this.booking.currentStop.toLong
          }
        })

        if (this.booking.currentStop.toAddress > 0 && this.booking.currentStop.fromAddress > 0 && this.booking.isSafeRoute) {
          await this.getBookingRoute(this.booking.currentStop)
        }
        await this.getStopRouteStatus(this.booking.currentStop)
      }
    },
    async timer () {
      this.counters = setInterval(async () => {
        if (this.booking.currentStop) {
          await this.getStopRouteStatus(this.booking.currentStop)
          this.markers = [{
            position: {
              lat: this.stopRoute.latitude, lng: this.stopRoute.longitude
            }
          }]
          this.circles.push({
            position: {
              lat: this.stopRoute.latitude, lng: this.stopRoute.longitude
            }
          })
        }
      }, 500 * 60)
    },
    showStopDetails (stop: any) {
      if (!this.booking.isActive) {
        this.showMessage = true;
        this.mediumMessage = 'Tur køres ikke i dag'
        return
      }

      if (this.booking.isMonitoringPaused) {
        this.showMessage = true;
        this.mediumMessage = 'Tur er på pause'
        return
      }

      const needsDeforst = this.booking.defrostState >= 0 && this.booking.defrostState < 3;

      if (needsDeforst) {
        this.showMessage = true;
        this.mediumMessage = 'Defrost skal afsluttes først'
        return;
      }

 
      this.$store.dispatch('stopStore/showStopDetails', stop)
      
    },
    showWash () {
      if (!this.booking.isActive) {
        return
      }
      if (this.booking.isMonitoringPaused) {
        return
      }

      if (this.booking.currentStop.isLastStop === false) {
        return
      }

      if (this.booking.currentStop.stopDone === false) {
        return
      }

      if (this.booking.status === 3) {
        return
      }

      this.$store.dispatch('bookingStore/toggleWashOpen')
    },
    toggleDeviation() {
      this.$store.commit('bookingStore/toggleDeviation')
    },
    setBookingActionState (actionState: string) : void {
      if (!this.booking.isActive) {
        return
      }
      if (actionState === 'IMAGESTART' && this.booking.imageStartState === 1) {
        return
      }
      if (actionState === 'IMAGEEND' && (this.booking.imageEndState === 1 || this.booking.currentStop.isLastStop === false)) {
        return
      }
      this.$store.dispatch('bookingStore/setBookingActionState', actionState) // PAUSE, STOP, CANCEL
    },
    async toggleSetTrailer() {
      if (!this.booking.isActive) {
        return
      }
      if (!this.canChangeTrailer()) {
        return
      }
      this.$store.commit('bookingStore/toggleSetTrailer')
      this.showSelect = false
    },
    onClickMap () {
      this.isMapOverlayVisible = true
    },
    closeMap () {
      this.isMapOverlayVisible = false
    },
    openBookingLogDetails (log: any) {
      if (!this.booking.isActive) {
        return
      }
      this.$store.commit('logStore/setSelectedBookingId', this.id)
      this.$store.dispatch('logStore/showLogDetails', log)
    },
    addRemark () {
      if (!this.booking.isActive) {
        return
      }
      this.$store.commit('logStore/setDataMode', 'create')
      this.$store.commit('logStore/toggleLogOpen')
    },
    handleSelect () {
      this.showSelect = !this.showSelect
    },
    handleClickOutside (event: MouseEvent) {
      if (this.$refs.dropdownRef instanceof HTMLElement) {
        const dropdownElement: HTMLElement = this.$refs.dropdownRef
        if (!dropdownElement.contains(event.target as Node)) {
          this.showSelect = false
        }
      }
    },
    async clickDefrost () {
      if (!this.booking.isActive) {
        return
      }
      if (this.booking.isMonitoringPaused) {
        return
      }
      if (this.defrost && this.defrost?.timeEnded !== null) {
        return
      }

      this.$store.commit('defrostStore/toggleOpen')
      this.showSelect = false
    },
    canChangeTrailer () {
      if (!this.booking.isActive) {
        return
      }
      if (!this.booking.trailer.id) {
        return false;
      }
      const trailerIds = this.trailerOptions.map((option: any) => option.vehicleId);
      const defrostState = this.booking.defrostState
      const washState = this.booking.washState
      const stops = this.booking.stops

      const isDefrostAndWashInactive = defrostState === -1 && washState <= 0
      const isAnyStopStarted = stops.some((stop: any) => stop.stopStarted)
      const hasTrailer = !!this.booking.trailerName
      return isDefrostAndWashInactive && !isAnyStopStarted && hasTrailer && trailerIds.includes(this.booking.trailer.id)
    },
    updateVisibleRange() {
      const stops = this.booking.stops || [];
      const currentStopIndex = stops.findIndex((stop: any) => stop.canStart === true);

      if (currentStopIndex !== -1) {
        this.showStart = Math.max(0, currentStopIndex - 2);
        this.showEnd = Math.min(stops.length, this.showStart + 5);
        if (this.showEnd - this.showStart < 5) {
          this.showStart = Math.max(0, this.showEnd - 5);
        }
      } else {
        this.showStart = 0;
        this.showEnd = Math.min(5, stops.length);
      }

      this.isLoading = false;
    },
    expand(direction: string) {
      const totalStops = this.booking.stops.length;
      if (direction === 'up' && this.showStart > 0) {
        this.showStart -= 5;
        this.showStart = Math.max(0, this.showStart);
      } else if (direction === 'down' && this.showEnd < totalStops) {
        this.showEnd += 5;
        this.showEnd = Math.min(totalStops, this.showEnd);
      }
    },
    formatDate(date: any) : string {
      return new Date(date).toLocaleDateString('da-DK')

    },
    shouldShowPlaceholder(index: number) {
      // Check if any stop has been started and not done
      const hasStartedStops = this.visibleStops.some((stop: any) => stop.stopStarted && !stop.stopDone);
      if (hasStartedStops) {
        return false;
      }

      // Check if the current stop can start a deviation and no stops have started
      return this.visibleStops[index].canStart;
    },
  },
  watch: {
    'booking.stops': {
      immediate: true,
      handler() {
        this.updateVisibleRange();
      }
    }
  }
})
</script>
