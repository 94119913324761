<template>
  <div class="relative z-50 w-full h-full" aria-labelledby="slide-over-title" role="dialog" aria-modal="true">
    <transition name="fade" enter-active-class="ease-in-out duration-500" leave-active-class="ease-in-out duration-500"
      enter-from-class="opacity-0" enter-to-class="opacity-100" leave-from-class="opacity-100"
      leave-to-class="opacity-0">
      <div v-if="isVisibleDeviation" class="fixed inset-0 bg-gray-700 bg-opacity-80 transition-opacity"></div>
    </transition>
    <transition name="slide" enter-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      enter-from-class="translate-x-full" enter-to-class="translate-x-0"
      leave-active-class="transform transition ease-in-out duration-500 sm:duration-700"
      leave-from-class="translate-x-0" leave-to-class="translate-x-full">
      <div v-if="isVisibleDeviation" class="fixed inset-0 overflow-hidden">
        <div class="absolute inset-0 overflow-hidden">
          <div class="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
            <div class="pointer-events-auto relative w-screen max-w-md">
              <div class="absolute left-0 top-0 -ml-8 flex pr-2 pt-4 sm:-ml-10 sm:pr-4">
                <button @click="close()" type="button"
                  class="relative rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                  <span class="absolute -inset-2.5"></span>
                  <span class="sr-only">Close panel</span>
                  <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor"
                    aria-hidden="true">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <div class="flex h-full flex-col overflow-y-scroll space-y-6 bg-gray-100 py-6 px-4 shadow-xl">
                <div>
                  <p>Afvigelse</p>
                  <p class="text-xl font-medium">Vælg afvigelsesårsag</p>
                </div>
                <div :class="{'opacity-50': activeDeviation || booking.currentStop.secondaryAddressId }">
                  <p>Hovedårsag</p>
                  <div class="relative">
                    <select id="transport_type"
                      :disabled="activeDeviation || booking.currentStop.secondaryAddressId"
                      :class="{'pointer-events-none': activeDeviation || booking.currentStop.secondaryAddressId}"
                      class="block w-full appearance-none h-10 text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6"
                      aria-labelledby="transport_type" v-model="selectedMainReason">
                      <option v-for="reason in combinedDeviationReasons" :key="reason.id" :value="reason.id">
                        {{ reason.name }}
                      </option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          fill="#6B7280" />
                      </svg>
                    </div>
                  </div>
                </div>
                <div :class="{'opacity-50': isSpecificationDisabled || (activeDeviation || booking.currentStop.secondaryAddressId) }">
                  <p>Specifikation</p>
                  <div class="relative">
                    <select id="specification" v-model="selectedSpecification"
                      :disabled="isSpecificationDisabled || (activeDeviation || booking.currentStop.secondaryAddressId)"
                      :class="{'pointer-events-none': isSpecificationDisabled || (activeDeviation || booking.currentStop.secondaryAddressId)}"
                      class="block w-full appearance-none h-10 text-sm font-medium rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-200 focus:ring-2 focus:ring-inset focus:ring-secondary sm:text-sm sm:leading-6">
                      <option v-for="spec in mutableSpecifications" :key="spec.id" :value="spec.id">
                        {{ spec.addressName }}
                      </option>
                    </select>
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-400">
                      <svg class="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
                          fill="#6B7280" />
                      </svg>
                    </div>
                  </div>
                </div>
                <button type="submit" @click="submit()"
                  :disabled="isSubmitDisabled"
                  :class="{'opacity-20 pointer-events-none': isSubmitDisabled}"
                  class="flex w-full justify-center rounded-md bg-primary px-3 py-2 font-medium leading-6 text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-secondary">
                  {{ (activeDeviation || booking.currentStop.secondaryAddressId) || booking.currentStop.secondaryAddressId ? 'Afslut afvigelse' : 'Angiv afvigelse' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import store from '@/store';
import { computed, defineProps, onMounted, watch, ref, reactive } from 'vue';
import stopRepository from '@/repositories/bookings/stopRepository';

const props = defineProps({
  isVisibleDeviation: Boolean,
  bookingId: String,
})


const close = () => {
  store.commit('bookingStore/toggleDeviation')
}

onMounted(async () => {
  await store.dispatch('bookingStore/getDeviationReasons')
  await store.dispatch('bookingStore/getBooking', props.bookingId)
  await fetchPossibleSafeRouteDestinations();

  if (selectedMainReason.value === imposedDeviationTypes.SAFE_ROUTE_DESTINATION) {
    mutableSpecifications.value = possibleDestinations.value || [];
  }

  if (booking.value.currentStop?.secondaryAddressId && possibleDestinations.value) {
    selectedMainReason.value = imposedDeviationTypes.SAFE_ROUTE_DESTINATION; // -1
    selectedSpecification.value = booking.value.currentStop.secondaryAddressId;
  }
})

const imposedDeviationTypes = {
  SAFE_ROUTE_DESTINATION: -1
}
const activeDeviation = computed(() => store.state.bookingStore.activeDeviation)
const booking = computed(() => store.state.bookingStore.booking)
const deviationReasons = computed(() => store.state.bookingStore.deviationReasons)

watch(() => props.isVisibleDeviation, async (newValue) => {
  console.log('isVisibleDeviation:', newValue);
  console.log('booking.value.currentStop.secondaryAddressId', booking.value.currentStop.secondaryAddressId);
  await fetchPossibleSafeRouteDestinations();
});

console.log('booking.currentStop.secondaryAddressId', booking.value.currentStop)

const selectedMainReason = ref(null);
const selectedSpecification = ref(null);
const possibleDestinations = ref([]);
const mutableSpecifications = ref([]);

const isLoading = ref(false);

const clientImposedReasons = reactive([
    { id: -1, name: "Sikker rute destination", specifications: [null] }
]);

const combinedDeviationReasons = computed(() => {
    return [...deviationReasons.value, ...clientImposedReasons];
});

const specifications = computed(() => {
  const reason = deviationReasons.value.find(r => r.id === selectedMainReason.value);
  return reason ? reason.specifications : [];
});

const isSpecificationDisabled = computed(() => {
  if (selectedMainReason.value < 0) {
    // If an imposed reason is selected, specifications should never be disabled
    return false;
  }
  return specifications.value?.length === 0;
});

const isSubmitDisabled = computed(() => {
  // Check if any main reason is selected
  if (!selectedMainReason.value) {
    return true;
  }

  // Conditions for imposed reasons
  if (selectedMainReason.value < 0) {
    // Check if a specification is selected when an imposed reason is chosen
    return !selectedSpecification.value;
  }

  // Check if either active deviation or secondaryAddressId is set
  if (activeDeviation.value || booking.value.currentStop.secondaryAddressId) {
    return false;
  }

  // Conditions for regular reasons
  const currentReason = deviationReasons.value.find(r => r.id === selectedMainReason.value);
  if (currentReason && currentReason.specifications.length > 0 && !selectedSpecification.value) {
    return true; // A specification is required but not selected
  }

  // Default to allow submission if none of the above conditions are met
  return false;
});

const fetchPossibleSafeRouteDestinations = async () => {
  try {
    // Prioritize stop that can start, if none exists, use the last stop
    const fromAddress = booking.value.currentStop?.fromAddress || stops.value[stops.value.length - 1]?.fromAddress;

    if (fromAddress) {
      const result = await stopRepository.getPossibleSafeRouteDestinations(fromAddress);
      console.log('fetchPossibleSafeRouteDestinations result', result)
      possibleDestinations.value = result.data;
    } else {
      console.error('No valid fromAddress found.');
      possibleDestinations.value = null;
    }
  } catch (error) {
    console.error('Failed to get possible safe route destinations:', error);
    possibleDestinations.value = null;
  }
};

watch(booking, async (newBooking) => {
  if (booking.value.currentStop?.secondaryAddressId && possibleDestinations.value) {
    selectedMainReason.value = imposedDeviationTypes.SAFE_ROUTE_DESTINATION; // -1
    selectedSpecification.value = booking.value.currentStop.secondaryAddressId;
  }
  if (selectedMainReason.value === imposedDeviationTypes.SAFE_ROUTE_DESTINATION) {
    mutableSpecifications.value = possibleDestinations.value || [];
  }
}, { immediate: true });

watch(activeDeviation, () => {
  if (activeDeviation.value !== null) {
    console.log('activeDeviation.value', activeDeviation.value)

    selectedMainReason.value = activeDeviation.value.deviationReasonId;
    selectedSpecification.value = activeDeviation.value.deviationSpecificationId;
  }
});

watch(selectedMainReason, (newVal) => {
  if (activeDeviation.value === null) {
    selectedSpecification.value = null;
  }

  if (newVal < 0) { // Imposed reasons
    switch (newVal) {
      case imposedDeviationTypes.SAFE_ROUTE_DESTINATION: // -1
        mutableSpecifications.value = possibleDestinations.value || [];
        break;
      default:
        break;
    }
  } else {
    const reasons = deviationReasons.value || [];
    if (Array.isArray(reasons)) {
      const reason = reasons.find(r => r.id === newVal);
      mutableSpecifications.value = reason ? reason.specifications : [];
    } else {
      mutableSpecifications.value = [];
    }
  }
}, { immediate: true });

const submit = async () => {
  if (isLoading.value) {
    return;
  }
  isLoading.value = true;
  if (selectedMainReason.value > 0) {
    if (!activeDeviation.value) {
      const payload = {
        bookingId: parseInt(props.bookingId),
        deviationReasonId: selectedMainReason.value,
        deviationSpecificationId: selectedSpecification.value,
        startTime: new Date(),
      };
      await store.dispatch('bookingStore/beginDeviation', payload);
    } else {
      const payload = {
        deviationId: activeDeviation.value.id,
        endTime: new Date(),
      };
      await store.dispatch('bookingStore/endDeviation', payload);
    }
    close();
  } else {
    switch (selectedMainReason.value) {
      case imposedDeviationTypes.SAFE_ROUTE_DESTINATION: // -1
        try {
          const stop = booking.value.stops.find(stop => stop.canStart) || stops.value[stops.value.length - 1];
          if (!booking.value.currentStop.secondaryAddressId) {
            const response = await stopRepository.toggleSecondaryRoute(stop.id, selectedSpecification.value);
            if (response && response.data) {
              store.commit('stopStore/setDetails', response.data)
              store.dispatch('bookingStore/getBooking', booking.value.id)
              close();
            }
          } else {
            const response = await stopRepository.toggleSecondaryRoute(stop.id, undefined);
            if (response && response.data) {
              store.commit('stopStore/setDetails', response.data)
              store.dispatch('bookingStore/getBooking', booking.value.id)
              close();
            }
          }
        } catch (error) {
          console.error('Failed to set safe route destination:', error);
        }
        console.log('selectedSpecification.value', selectedSpecification.value);
        break;
    }
  }
  isLoading.value = false;
}

</script>
