<template>
  <div class="relative w-full">
    <div class="flex items-center border rounded h-10 relative">
      <input 
        v-if="!selectedItem"
        type="text" 
        v-model="searchQuery" 
        @focus="showAllResults" 
        @blur="hideResults"
        @input="filterResults"
        class="w-full px-4 py-2 outline-none rounded appearance-none"
        placeholder="Søg..."
      />
      <div v-else class="w-full px-4 py-2">
        {{ selectedItem.text }}
      </div>
      <div class="absolute inset-y-0 right-0 flex items-center px-2 text-gray-400 z-10">
        <svg v-if="!selectedItem" :class="{'transform rotate-180': showResults}" class="h-5 w-5 text-gray-400 transition-transform duration-200" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29289 7.29289C5.68342 6.90237 6.31658 6.90237 6.7071 7.29289L9.99999 10.5858L13.2929 7.29289C13.6834 6.90237 14.3166 6.90237 14.7071 7.29289C15.0976 7.68342 15.0976 8.31658 14.7071 8.70711L10.7071 12.7071C10.3166 13.0976 9.68341 13.0976 9.29289 12.7071L5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z" fill="#6B7280"/>
        </svg>
        <svg v-else @click="deselectItem" class="h-5 w-5 text-gray-400 cursor-pointer" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 8.586L4.707 3.293a1 1 0 0 0-1.414 1.414L8.586 10l-5.293 5.293a1 1 0 0 0 1.414 1.414L10 11.414l5.293 5.293a1 1 0 0 0 1.414-1.414L11.414 10l5.293-5.293a1 1 0 0 0-1.414-1.414L10 8.586z" fill="#6B7280"/>
        </svg>
      </div>
    </div>
    <ul v-if="showResults" ref="dropdownList" class="absolute w-full bg-white border rounded shadow-lg max-h-60 overflow-y-auto mt-1 z-20">
      <li 
        v-for="(item, index) in filteredItems" 
        :key="index" 
        @mousedown.prevent="selectItem(item)"
        class="px-4 py-2 cursor-pointer hover:bg-gray-200"
      >
        {{ item.text }}
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent, ref, watch, onMounted } from 'vue';

export default defineComponent({
  name: 'SmartSelect',
  props: {
    items: {
      type: Array,
      required: true,
    }
  },
  emits: ['update:value'],
  setup(props, { emit }) {
    const searchQuery = ref('');
    const showResults = ref(false);
    const filteredItems = ref([...props.items]);
    const selectedItem = ref(null);
    const dropdownList = ref(null);

    const adjustDropdownPosition = () => {
      const dropdown = dropdownList.value;
      if (dropdown) {
        const rect = dropdown.getBoundingClientRect();
        const viewportHeight = window.innerHeight;
        const viewportWidth = window.innerWidth;

        if (rect.bottom > viewportHeight) {
          dropdown.style.maxHeight = `${viewportHeight - rect.top - 20}px`;
        }
        if (rect.left < 0) {
          dropdown.style.left = '0px';
        }
        if (rect.right > viewportWidth) {
          dropdown.style.right = '0px';
        }
      }
    };

    const showAllResults = () => {
      filteredItems.value = [...props.items];
      showResults.value = true;
      adjustDropdownPosition();
    };

    const filterResults = () => {
      const query = searchQuery.value.toLowerCase();
      filteredItems.value = props.items.filter(item =>
        item.text && item.text.toLowerCase().includes(query)
      );
      console.log('Filtered Items:', filteredItems.value);
      adjustDropdownPosition();
    };

    watch(
      () => props.items,
      (newItems) => {
        filteredItems.value = newItems;
        filterResults();
      },
      { immediate: true }
    );

    const hideResults = () => {
      setTimeout(() => {
        showResults.value = false;
      }, 200); // Delay to allow item selection before hiding
    };

    const selectItem = (item) => {
      searchQuery.value = item.text;
      selectedItem.value = item;
      showResults.value = false;
      emit('update:value', item.id);
    };

    const deselectItem = () => {
      selectedItem.value = null;
      searchQuery.value = '';
      showResults.value = false;
      emit('update:value', null);
    };

    onMounted(() => {
      window.addEventListener('resize', adjustDropdownPosition);
    });

    return {
      searchQuery,
      showResults,
      filteredItems,
      selectedItem,
      dropdownList,
      showAllResults,
      filterResults,
      hideResults,
      selectItem,
      deselectItem
    };
  }
});
</script>
