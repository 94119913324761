import bookingRepository from '@/repositories/bookings/bookingRepository'
import { Commit, Dispatch } from 'vuex'

const logStore = {
  namespaced: true,
  state: {
    logOpen: false,
    logList: {},
    logDetails: {},
    selectedBookingId: undefined,
    selectedStopId: undefined,
    dataMode: undefined
  },
  getters: {
    logOpen (state: any) {
      return state.logOpen
    },
    logList (state: any) {
      return state.logList
    },
    logDetails (state: any) {
      return state.logDetails
    },
    selectedBookingId (state: any) {
      return state.selectedBookingId
    },
    selectedStopId (state: any) {
      return state.selectedStopId
    },
    dataMode (state: any) {
      return state.dataMode
    }
  },
  mutations: {
    toggleLogOpen (state: any) {
      state.logOpen = !state.logOpen
      console.log(state.logOpen)
    },
    setLogList (state: any, response: any) {
      state.logList = response
    },
    setLogDetails (state: any, response: any) {
      state.logDetails = response
      console.log('log details set to: ', state.logDetails)
    },
    setSelectedBookingId (state: any, id: number) {
      state.selectedBookingId = id
    },
    setSelectedStopId (state: any, id: number) {
      state.selectedStopId = id
    },
    setDataMode (state: any, mode: string) {
      state.dataMode = mode
    }
  },
  actions: {
    async getBookingDriverLog ({ commit }: { commit: Commit }, bookingId: number) {
      try {
        const response = await bookingRepository.getBookingDriverLog(bookingId)
        commit('setLogList', response.data)
      } catch (error) {
        console.log('error', error)
      }
    },
    async getDriverLogById ({ commit, state }: {commit: Commit, state: any }, id: number) {
      try {
        const response = await bookingRepository.getDriverLogById(id, state.selectedBookingId)
        if (response && response.data) {
          commit('setLogDetails', response.data)
        }
      } catch (error) {
        console.log('error', error)
      }
    },
    async showLogDetails ({ commit, dispatch }: { commit: Commit, dispatch: Dispatch }, log: any) {
      if (log.logType === 'System') {
        commit('setDataMode', 'read')
      }
      if (log.id && log.logType !== 'System') {
        commit('setDataMode', 'update')
        await dispatch('getDriverLogById', log.id)
        commit('toggleLogOpen')
        return
      }
      commit('setLogDetails', log)
      commit('toggleLogOpen')
    }
  }
}

export default logStore
